import type { I18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { SaveIcon } from "lucide-react";
import { zodResolver } from "mantine-form-zod-resolver";
import { useEffect } from "react";
import { z } from "zod";
import type { PatientIdentity } from "~/types";
import { useAppState } from "~/utils/states";
import {
	apiFetcher,
	dateParser,
	handleDateValidation,
} from "~/utils/utils.client";
import { getIdentityNameById, idTypeLabels } from "./sign-in-box";

type Props = {
	opened: boolean;
	close: () => void;
};

const schema = (i18n: I18n) =>
	z.object({
		identityTypeId: z.preprocess(
			(value) =>
				typeof value === "string" ? Number.parseInt(value, 10) : value,
			z.number(),
		),
		identityNumber: z.string().min(2, t(i18n)`Wajib diisi`),
		salutation: z.string().min(1, t(i18n)`Wajib diisi`),
		name: z.string().min(1, t(i18n)`Wajib diisi`),
		dob: z.date(),
		phone: z
			.string()
			.min(4, t(i18n)`Wajib diisi`)
			.regex(/^\d+$/, t(i18n)`Hanya angka yang diperbolehkan`),
		bloodType: z.string(),
		sex: z.string().min(1, t(i18n)`Wajib diisi`),
		email: z
			.string()
			.min(3, t(i18n)`Wajib diisi`)
			.email(t(i18n)`Email tidak valid`),
	});

export default function NewPatientModal({ opened, close }: Props) {
	const { i18n } = useLingui();
	const { faskes } = useAppState();
	const form = useForm({
		initialValues: {
			identityTypeId: "",
			identityNumber: "",
			salutation: "",
			name: "",
			dob: new Date("2000-01-01"),
			phone: "",
			bloodType: "",
			sex: "",
			email: "",
		},
		validate: zodResolver(schema(i18n)),
	});

	const { data: patientIdentityList } = useQuery({
		queryKey: ["fetch-identity-list"],
		queryFn: async () => {
			if (faskes) {
				return apiFetcher<PatientIdentity[]>({
					path: "/v1/booking-online/patient/identity-list",
					opts: {},
				});
			}
		},
		refetchOnWindowFocus: false,
		enabled: faskes !== null,
	});

	const { mutate: submitHandler, isPending } = useMutation({
		mutationKey: ["patient-create"],
		mutationFn: async () => {
			const parsedValues = schema(i18n).parse(form.values);
			const dob = dayjs(form.values.dob).format("YYYY-MM-DD");
			if (!handleDateValidation(dob)) return;

			const results = await apiFetcher({
				path: "/v1/booking-online/patient/create",
				opts: {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						...parsedValues,
						dob: dob,
					}),
				},
			});

			return results;
		},
		onSuccess: (data) => {
			if (data.success === false) {
				notifications.show({
					message: data.message,
					title: t(i18n)`Terjadi kegagalan`,
					color: "red",
					withCloseButton: true,
				});
			} else {
				notifications.show({
					message: data.message,
					title: t(i18n)`Berhasil`,
					color: "green",
					withCloseButton: true,
				});
				close();
			}
			form.reset();
		},
		onError: (error) => {
			notifications.show({
				message: error.message,
				title: t(i18n)`Terjadi kegagalan`,
				color: "red",
				withCloseButton: true,
			});
		},
	});

	useEffect(() => {
		if (
			patientIdentityList &&
			patientIdentityList.length > 0 &&
			!form.values.identityTypeId
		) {
			form.setFieldValue(
				"identityTypeId",
				patientIdentityList[0].id.toString(),
			);
		}
	}, [form, patientIdentityList]);

	return (
		<>
			<Modal
				opened={opened}
				onClose={close}
				size={"lg"}
				centered
				styles={{
					header: { color: "white", background: "#093c71", padding: "1rem" },
					close: { color: "white", background: "#093c71" },
				}}
				title={
					<span className="text-lg">
						<Trans>Pendaftaran Pasien Baru</Trans>
					</span>
				}
			>
				<form onSubmit={form.onSubmit(() => submitHandler())}>
					<div className="flex flex-col md:flex-row mb-4 gap-4 mt-4">
						<div className="w-full">
							<div className="flex flex-col gap-4">
								<div className="flex flex-row gap-2">
									<div className="w-1/3">
										<Select
											withAsterisk
											key={form.key("identityTypeId")}
											{...form.getInputProps("identityTypeId")}
											label={
												<strong>
													<Trans>Jenis ID</Trans> :{" "}
												</strong>
											}
											placeholder={t(i18n)`Pilih`}
											data={
												patientIdentityList?.map((identity) => ({
													value: identity.id.toString(),
													label:
														idTypeLabels(i18n)[identity.name] ||
														t(i18n)`${identity.name}`,
												})) || []
											}
											allowDeselect={false}
										/>
									</div>
									<div className={"w-2/3"}>
										<TextInput
											key={form.key("identityNumber")}
											{...form.getInputProps("identityNumber")}
											label=" "
											placeholder={t(
												i18n,
											)`Masukkan No. ${getIdentityNameById(patientIdentityList, +form.values.identityTypeId, i18n)}`}
										/>
									</div>
								</div>

								<div className="flex flex-row gap-2">
									<div className="w-1/3">
										<Select
											key={form.key("salutation")}
											{...form.getInputProps("salutation")}
											withAsterisk
											label={t(i18n)`Nama Lengkap`}
											placeholder={t(i18n)`Sapaan`}
											data={[
												{ label: t(i18n)`Tuan`, value: "TUAN" },
												{ label: t(i18n)`Nona`, value: "NONA" },
												{ label: t(i18n)`Nyonya`, value: "NYONYA" },
												{ label: t(i18n)`Anak`, value: "ANAK" },
											]}
										/>
									</div>
									<div className="w-2/3">
										<TextInput
											key={form.key("name")}
											{...form.getInputProps("name")}
											label=" "
											placeholder={t(i18n)`Misal: Dewi Anggraini`}
										/>
									</div>
								</div>
								<DateInput
									key={form.key("dob")}
									{...form.getInputProps("dob")}
									withAsterisk
									label={t(i18n)`Tanggal Lahir`}
									valueFormat="DD/MM/YYYY"
									placeholder={t(i18n)`Format: DD/MM/YYYY`}
									dateParser={(value) => dateParser(value)}
								/>
								<TextInput
									key={form.key("phone")}
									{...form.getInputProps("phone")}
									withAsterisk
									label={t(i18n)`No. Telepon / HP`}
									type="tel"
									inputMode="numeric"
									autoComplete="tel"
								/>
								<div className="flex flex-row gap-2">
									<Select
										key={form.key("bloodType")}
										{...form.getInputProps("bloodType")}
										className="w-1/2"
										label={t(i18n)`Golongan darah`}
										placeholder={t(i18n)`Pilih`}
										data={["A", "B", "AB", "O"]}
									/>
									<Select
										key={form.key("sex")}
										{...form.getInputProps("sex")}
										className="w-1/2"
										withAsterisk
										label={t(i18n)`Jenis Kelamin`}
										placeholder={t(i18n)`Pilih`}
										data={[
											{ label: t(i18n)`Laki-laki`, value: "L" },
											{ label: t(i18n)`Perempuan`, value: "P" },
										]}
									/>
								</div>
								<TextInput
									key={form.key("email")}
									{...form.getInputProps("email")}
									withAsterisk
									label={t(i18n)`Email`}
									type="email"
								/>
							</div>
						</div>
					</div>
					<Button type="submit" loading={isPending} fullWidth color="tm-blue">
						<SaveIcon width={16} className="mr-2" /> <Trans>Simpan</Trans>
					</Button>
				</form>
			</Modal>
		</>
	);
}
