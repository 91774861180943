import type { I18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
	Alert,
	Box,
	Button,
	Card,
	Image,
	SegmentedControl,
	Select,
	Skeleton,
	TextInput,
	Title,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useFetcher, useSearchParams } from "@remix-run/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { zodResolver } from "mantine-form-zod-resolver";
import { useEffect, useState } from "react";
import { z } from "zod";
import type { Patient, PatientIdentity } from "~/types";
import { useAppState } from "~/utils/states";
import {
	apiFetcher,
	dateParser,
	handleDateValidation,
} from "~/utils/utils.client";
import Logo from "/logo.webp";
import HelpDeskModal from "./help-desk-modal";
/*eslint-disable import/no-unresolved*/
import NewPatientModal from "./new-patient-modal";

const schema = (i18n: I18n) =>
	z.object({
		dob: z.date().nullable(),
		identityType: z.enum(["idType", "mr"]),
		identityTypeId: z.preprocess(
			(value) =>
				typeof value === "string" ? Number.parseInt(value, 10) : value,
			z.number(),
		),
		identityNumber: z.string().min(2, t(i18n)`Wajib diisi`),
	});

export const idTypeLabels = (i18n: I18n): { [key: string]: string } => ({
	KTP: t(i18n)`KTP`,
	KITAS: t(i18n)`KITAS`,
	PASPOR: t(i18n)`PASPOR`,
});

export const getIdentityNameById = (
	patientIdentityList: PatientIdentity[] | undefined,
	id: number,
	i18n: I18n,
) => {
	const findIdentity = patientIdentityList?.find((v) => v.id === id);
	return findIdentity ? idTypeLabels(i18n)[findIdentity.name] : "";
};

export default function SignInBox() {
	const { i18n } = useLingui();
	const [opened, { open, close }] = useDisclosure(false);
	const [openedHelp, { open: openHelp, close: closeHelp }] =
		useDisclosure(false);
	const fetcher = useFetcher();
	const { faskes, banners } = useAppState();
	const [logoUrl, setLogoUrl] = useState<string | null>(null);
	const [params] = useSearchParams();
	const [helpText, setHelpText] = useState<string | null>(
		faskes?.batal_booking_setting.is_show_help_text
			? faskes.batal_booking_setting.help_text
			: null,
	);

	useQuery({
		queryKey: ["fetch-help-text"],
		queryFn: async () => {
			const res = await apiFetcher<{ help_text: string | null }>({
				path: "/v1/booking-online/booking/gethelpdesk",
				opts: {},
			});
			setHelpText(res.help_text);
			return res;
		},
	});

	const { data: patientIdentityList } = useQuery({
		queryKey: ["fetch-identity-list"],
		queryFn: async () => {
			if (faskes) {
				return apiFetcher<PatientIdentity[]>({
					path: "/v1/booking-online/patient/identity-list",
					opts: {},
				});
			}
		},
		refetchOnWindowFocus: false,
		enabled: faskes !== null,
	});

	useEffect(() => {
		if (banners !== null && faskes !== null) {
			const url = banners.find(
				(banner) => banner.template_nama_variabel === "login_logo_file",
			);
			setLogoUrl(
				url
					? `/static-api/pelayanan1/uploads/banner_booking/${url.template_file}`
					: Logo,
			);
		}
	}, [banners, faskes]);

	const form = useForm({
		mode: "controlled",
		initialValues: {
			identityType: "idType",
			identityTypeId: "",
			identityNumber: "",
			dob: new Date("2000-01-01"),
		},
		validate: zodResolver(schema(i18n)),
	});

	const { mutate: submitHandler, isPending } = useMutation({
		mutationKey: ["patient-check"],
		mutationFn: async () => {
			const parsedValues = schema(i18n).parse(form.values);
			const dob = dayjs(form.values.dob).format("YYYY-MM-DD");
			if (!handleDateValidation(dob)) return;

			const results = await apiFetcher({
				path: "/v1/booking-online/patient",
				opts: {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						...parsedValues,
						dob: dob,
					}),
				},
			});

			if (results === null) {
				notifications.show({
					message: t(i18n)`Pasien tidak ditemukan`,
					color: "red",
					withCloseButton: true,
				});
			}

			return results;
		},
		onSuccess: (data) => {
			const photo = data.pasien_foto
				? data.pasien_foto.includes("http")
					? data.pasien_foto
					: `/static-api/pelayanan1/uploads/pasien/${data.pasien_foto}`
				: null;

			const patient: Patient = {
				address: data.pasien_alamat || "",
				dob: data.pasien_tanggallahir,
				id: data.pasien_id,
				name: data.pasien_nama,
				email: data.pasien_email,
				nik: data.pasien_nik,
				norm: data.pasien_norm,
				image: photo || "",
				phone: data.pasien_nohp,
				sex: data.pasien_kelamin,
				verified: data.pasien_norm.length > 0,
			};

			// set cookie
			fetcher.submit(
				{
					patient: JSON.stringify(patient),
					next: params.get("next") ?? "/app",
				},
				{
					method: "post",
				},
			);
		},
	});
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	useEffect(() => {
		if (
			patientIdentityList &&
			patientIdentityList.length > 0 &&
			!form.values.identityTypeId
		) {
			form.setFieldValue(
				"identityTypeId",
				patientIdentityList[0].id.toString(),
			);
		}
	}, [form, patientIdentityList]);

	return (
		<div className="flex flex-col items-center w-full md:w-2/3 lg:w-1/2">
			<Box pos="relative" w={100} h={100}>
				{!isImageLoaded && (
					<Skeleton height={100} circle pos="absolute" top={0} left={0} />
				)}
				<Image
					src={logoUrl}
					alt="logo"
					width={100}
					height={100}
					fit="cover"
					style={{
						opacity: isImageLoaded ? 1 : 0,
						transition: "opacity 0.3s",
					}}
					onLoad={() => setIsImageLoaded(true)}
					onError={(e) => {
						e.currentTarget.src = Logo;
					}}
				/>
			</Box>

			<div className="flex flex-col items-center">
				<Title order={4} className="font-regular" c="tm-blue">
					<Trans>Pendaftaran Online</Trans>
				</Title>
				{faskes ? (
					<Title order={2} className="font-regular text-center" c="tm-blue">
						{faskes.name}
					</Title>
				) : (
					<Skeleton width={300} height={40} />
				)}
			</div>

			{/* SESSION EXPIRED ALERT */}
			{params.get("reason") === "session-expired" && (
				<Alert mt={"sm"} variant="light" className="text-center">
					<Trans>Mohon maaf, sesi Anda telah habis. Silakan login ulang.</Trans>
				</Alert>
			)}

			<Card
				style={{ backgroundColor: "transparent" }}
				className="mt-4"
				padding={"md"}
				classNames={{ root: "w-full" }}
			>
				<form
					onSubmit={form.onSubmit(() => submitHandler())}
					className="w-full flex flex-col gap-2"
				>
					<SegmentedControl
						value={form.values.identityType}
						onChange={(v) => form.setFieldValue("identityType", v)}
						fullWidth
						data={[
							{ value: "idType", label: t(i18n)`Jenis ID` },
							{ value: "mr", label: t(i18n)`Dengan No. RM` },
						]}
					/>

					<div className="flex flex-row gap-2">
						{form.values.identityType === "idType" && (
							<div className="w-1/3">
								<Select
									key={form.key("identityTypeId")}
									{...form.getInputProps("identityTypeId")}
									label={
										<strong>
											<Trans>Jenis ID</Trans> :{" "}
										</strong>
									}
									placeholder={t(i18n)`Pilih`}
									data={
										patientIdentityList?.map((identity) => ({
											value: identity.id.toString(),
											label:
												idTypeLabels(i18n)[identity.name] ||
												t(i18n)`${identity.name}`,
										})) || []
									}
									allowDeselect={false}
								/>
							</div>
						)}
						<div
							className={
								form.values.identityType === "idType" ? "w-2/3" : "w-full"
							}
						>
							<TextInput
								key={form.key("identityNumber")}
								{...form.getInputProps("identityNumber")}
								label={
									form.values.identityType === "idType" ? (
										" "
									) : (
										<strong>
											<Trans>No. RM</Trans> :{" "}
										</strong>
									)
								}
								placeholder={
									form.values.identityType === "idType"
										? t(
												i18n,
											)`Masukkan No. ${getIdentityNameById(patientIdentityList, +form.values.identityTypeId, i18n)}`
										: t(i18n)`Masukkan No. RM`
								}
							/>
						</div>
					</div>

					<DateInput
						key={form.key("dob")}
						{...form.getInputProps("dob")}
						label={
							<strong>
								<Trans>Tanggal Lahir</Trans> :
							</strong>
						}
						valueFormat="DD/MM/YYYY"
						className="mb-4"
						placeholder={t(i18n)`Format: DD/MM/YYYY`}
						dateParser={(value) => dateParser(value)}
						onBlur={(event) => handleDateValidation(event.target.value)}
					/>

					<Button loading={isPending} type="submit" color="tm-blue">
						<Trans>Masuk</Trans>
					</Button>
					<Button loading={isPending} color="tm-green" onClick={open}>
						<Trans>Daftar Pasien Baru</Trans>
					</Button>
					<Button hidden={!!helpText} color="cyan" onClick={openHelp}>
						<Trans>Bantuan</Trans>
					</Button>
				</form>
			</Card>

			<NewPatientModal opened={opened} close={close} />
			<HelpDeskModal
				open={openedHelp}
				close={closeHelp}
				text={helpText || ""}
			/>
		</div>
	);
}
